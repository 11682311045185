import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 25px;

`;

export const PathBreadcrumb = styled.ul`
    font-size: 14px;

    list-style: none;
    
    margin: 0 1em;
    padding: 0 1em;
    li{
        display: inline-block;
        a{
            color: #555;
            font-size: 0.71em;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    span{
        padding: 0 5px;
        color: #ccc;
        font-size: 0.71em;
    }
`;