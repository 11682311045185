// import React from 'react';

export const ArrowLeft = () => {
    return(
        `<`
    )
}

export const ArrowRight = () => {
    return(
        `>`
    )
}
