import React, { useContext, useState, useEffect } from 'react';
import Part from '../part/part.component';
import { CartContext } from '../../providers/cart/cart.provider';

import { PartCollectionDiv } from './part-collection.styles';

const PartCollection = () => {
    const { onlyBeltsButton, currentPARTS } = useContext(CartContext);

    const collectionsMap = currentPARTS;
    const collections = Object.keys(collectionsMap).map(
        key => collectionsMap[key]
    );

    const [widthSize, setWidthSize ] = useState(window.innerWidth);
    const [buttonMobile, setButtonMobile] = useState(false);
    
    window.onresize = function(e) {
        setWidthSize(window.innerWidth);
    };

    useEffect(() => {
        if(widthSize < 769){
            setButtonMobile(true);
        }else if(widthSize > 768){
            setButtonMobile(false);
        }
    }, [widthSize])

    return(
        <PartCollectionDiv dirFlex={onlyBeltsButton}>
            {   
                collections.map(({id, isActive, isRant, ...otherCollectionProps}) => (
                    <Part key={id} id={id} isRant={isRant} isActive={isActive} buttonMobile={buttonMobile} {...otherCollectionProps}/>
                ))
            }
        </PartCollectionDiv>
    )
}

export default PartCollection;