import React from 'react';
import { BreadcrumbsContainer, PathBreadcrumb } from './breadcrumbs.styles';

const Breadcrumbs = () => {
    return(
        <BreadcrumbsContainer>
            <PathBreadcrumb>
                <li><a href="https://time-team.ru"><i className={'fa fa-home'}></i></a></li>
                <span>/</span>
                <li><a href="https://custom.time-team.ru">G-SHOCK Custom</a></li>
            </PathBreadcrumb>
        </BreadcrumbsContainer>
    )
}

export default Breadcrumbs;