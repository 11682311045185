import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const PartDiv = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    align-items: flex-start;
    will-change: transform;
    display: flex;
`;

export const PartImg = styled(LazyLoadImage)`
    width: 100%;
    height: auto;
    align-items: flex-start;
    margin: 0;
    margin-bottom: -4.5px;
    padding: 0;
    z-index: 1;
`;

export const DialContainer = styled(LazyLoadImage)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
`;

const buttonParam = '42px';

export const ButtonArrow = styled.button`
    width: ${buttonParam};
    height: ${buttonParam};
    position: absolute;
    border-radius: 50%;
    background-color: rgba(30,30,30,1.0);
    color: #fff;
    padding: 0;
    border: none;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    font-weight: lighter;
    ${props => props.left ? `left: -${buttonParam};` : null}
    ${props => props.right ? `right: -${buttonParam};` : `right: 0;`}
    transition: 0.2s ease;
`;
