import styled  from 'styled-components';

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 5px;
`;

export const TabPane = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.6em 1.2em;
    border-radius: 2em;
    font-size: 1em;
    transition: 0.2s ease;
    ${props => props.active ? `
    background-color: #bbbbbb;
    font-weight: 700;
    color: #555;
    ` : `
    background-color: #e6e6e6;
    font-weight: 500;
    cursor: pointer;
    &:hover{
        transform: scale(1.02);
    }
    &:active{
        transform: scale(0.96);
    }`}
`;

export const Content = styled.div`

`;

export const CollectionContainer = styled.div`
    margin-top: 15px;
    display: grid;
    ${props => props.belt ? `grid-template: repeat(6, 1fr) / repeat(6, 1fr);` : `grid-template: repeat(4, 1fr) / repeat(4, 1fr);`}
    ${props => props.rant ? `grid-template: repeat(5, 1fr) / repeat(5, 1fr);` : null}
    grid-gap: 5px;
`;

export const CollectionItem = styled.div`
    border-radius: 15px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    ${props => props.belts ? `align-items: flex-end;` : `align-items: center;`}
    transition: 0.5s ease;
    border: 1px solid transparent;
    padding: 2px;
    span{
        ${props => props.belts ? `width: 50%;` : `width: 100%;`}
        ${props => props.belts ?
        (`
            &:first-child{
                img{
                    margin-bottom: -4px;
                }
            }
            &:last-child{
                transform: rotate(-180deg);
            }
        `) : null}
        ${props => props.rotate === true ?
        `&:last-child{
            transform: rotate(-180deg)
        }` : null}
    }

    &:hover{
        border: 1px solid #999;
    }

    &.active{
        border-color: #888;
        transform: scale(1.048);
        backface-visibility: hidden;
    }

`;

