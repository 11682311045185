import styled from 'styled-components';

export const RandButton = styled.button`
    border: none;
    border-radius: 28px;

    color: #fff;
    background: #dd5e89; 
    background: -webkit-linear-gradient(to right, #dd5e89, #f7bb97); 
    background: linear-gradient(to right, #dd5e89, #f7bb97);
    box-shadow: 0 0 5px rgba(0,0,0,0.05);
    
    margin: 15px auto;
    padding: 0.6em 1em;

    font-size: 14px;
    font-weight: 700;
    transition: 0.2s ease;
    &:hover{
        transform: scale(1.02);
    }
    &:active{
        transform: scale(0.96);
    }
`;