import React, { useContext } from 'react';
import { CartContext } from '../../providers/cart/cart.provider';
import YandexButton from '../YandexButton/button.component';
// import SwitchWatch from '../switchWatch/switchwatch.component';
import QuickType from '../quickctype/quicktype.component';
import Random from '../random/random.component';

import { 
    TextBlockDiv, 
    ChooseTotal,
    TotalContainer,
    TotalP, 
    DeliveryP, 
    SwitchContainer, 
    SwitchTypes,
    PriceSpan,
    ButtonText
 } from './text-block.styles';


const TextBlock = () => {
    const {
        topSlide, 
        middleSlide, 
        bottomSlide, 
        complete, 
        setComplete, 
        originBelts, 
        setOriginBelts,
        onlyRantsButton,
        onlyBeltsButton,
        setOnlyRantsButton,
        setOnlyBeltsButton,
        setTopSlide,
        currentArr
    } = useContext(CartContext);

    //Модели
    let titleParts = [currentArr[0].items[topSlide-1], currentArr[1].items[middleSlide-1], currentArr[2].items[bottomSlide-1]]; // Модели

    function hanldeComplete(){
        setComplete(true);
        setOnlyRantsButton(false);
        setOnlyBeltsButton(false);
        currentArr[0].isActive = true;
        currentArr[1].isActive = true;
        currentArr[2].isActive = true;
    }
    function handleOnlyRants(){
        setOnlyRantsButton(true);
        setOnlyBeltsButton(false);
        setComplete(false);
        setOriginBelts(false);
        if( !onlyRantsButton ) {
            currentArr[0].isActive = false;
            currentArr[2].isActive = false;
            currentArr[1].isActive = true;
        }else {
            currentArr[0].isActive = true;
            currentArr[2].isActive = true; 
            currentArr[1].isActive = false;
        }
    }
    function handleOnlyBelts(){
        setOnlyBeltsButton(true);
        setOnlyRantsButton(false);
        setComplete(false);
        if( !onlyBeltsButton ) {
            currentArr[1].isActive = false;
            currentArr[0].isActive = true;
            currentArr[2].isActive = true; 
        }else {
            currentArr[1].isActive = true;
            currentArr[0].isActive = false;
            currentArr[2].isActive = false;
        }
    }
    function handleOriginBelts(){
        setOriginBelts(!originBelts)
        setTopSlide(bottomSlide);
        if( originBelts ){
            currentArr[2].title = 'Ремешок ( низ )';
        }else {
            currentArr[2].title = 'Ремешки';
        }
    }

    //Текстовый блок
    let models = '';
    let titleShop = 'Time x Team - ';
    
    if(complete){
        models = `${currentArr[0].isActive ? `Ремень.Верх: ${currentArr[0].items[topSlide-1].model}, ` : ''}Рант: ${currentArr[1].items[middleSlide-1].model} ${currentArr[2].isActive ? `, Ремень.Низ: ${currentArr[2].items[bottomSlide-1].model}` : ''}`;
        titleShop += `Комплект[A${topSlide}F${middleSlide}E${bottomSlide}`;
    }else if(onlyRantsButton){
        models = `Рант: ${currentArr[1].items[middleSlide-1].model}`;
        titleShop += `Рант[OR${middleSlide}`;
    }else if(onlyBeltsButton){
        models = `Ремень.Верх: ${currentArr[0].items[topSlide-1].model}, Ремень.Низ: ${currentArr[2].items[bottomSlide-1].model}`;
        titleShop += `Ремешки[OB${topSlide}AO${bottomSlide}`;
    }
    titleShop += ']';
    

    //Ценовой блок
    let price = 0;
    if(currentArr[0].isActive){
        price += currentArr[0].items[topSlide-1].price;
    }
    if(currentArr[1].isActive){
        price += currentArr[1].items[middleSlide-1].price;
    }
    if(currentArr[2].isActive){
        price += currentArr[2].items[bottomSlide-1].price;
    }

    let bonus = 0;
    let differentBelts = currentArr[0].items[topSlide-1].model !== currentArr[2].items[bottomSlide-1].model;
    if( differentBelts && !onlyRantsButton ){
        bonus = 0.5*(currentArr[0].items[topSlide-1].price+currentArr[2].items[bottomSlide-1].price);
    }
    let total = price + bonus;
    total = Math.round(total)+200;
 
    return(
        <TextBlockDiv>
            {/* <SwitchWatch /> */}
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <SwitchContainer>
                        {
                            !complete ? 
                            <SwitchTypes onClick={hanldeComplete}>
                                <img src={`https://www.time-team.ru/image/r/rant.svg`} width={32} height={32} alt={`РАНТ`}/>
                                <img src={`https://www.time-team.ru/image/r/belts.svg`} width={32} height={32} alt={`РЕМЕШКИ`}/>
                            </SwitchTypes> : null
                        }
                        {
                            !onlyRantsButton ?
                            <SwitchTypes onClick={handleOnlyRants}>
                                <img src={`https://www.time-team.ru/image/r/rant.svg`} width={32} height={32} alt={`РАНТ`}/>
                            </SwitchTypes> : null
                        }
                        {
                            !onlyBeltsButton ?
                            <SwitchTypes onClick={handleOnlyBelts}>
                                <img src={`https://www.time-team.ru/image/r/belts.svg`} width={32} height={32} alt={`РЕМЕШКИ`}/>
                            </SwitchTypes> : null
                        }
                        {
                            !onlyRantsButton ?
                            <SwitchTypes style={{maxWidth: `130px`}} onClick={handleOriginBelts}>
                                { originBelts ? 'Разные ремешки' : 'Одинаковые ремешки' }
                            </SwitchTypes>
                            : null

                        }
                    </SwitchContainer>         

                <QuickType />

                <Random />

            </div>

            <TotalContainer>
                <h3>Выбрано:</h3>
                {   
                    originBelts ? 
                        currentArr.slice(1,3).map(({id, isActive, type}) => {
                            return(
                                isActive ? 
                                    <ChooseTotal key={id}>
                                        {
                                            type === 'middle' ?
                                            <ButtonText> Рант {titleParts[id-1].kind} <br /> {titleParts[id-1].model} <br /> <PriceSpan>{titleParts[id-1].price} руб.</PriceSpan> </ButtonText>
                                            :
                                            <ButtonText> Ремень х2 {titleParts[id-1].kind} <br /> {titleParts[id-1].model} <br /> <PriceSpan>{titleParts[id-1].price * 2} руб.</PriceSpan> </ButtonText>
                                        }
                                    </ChooseTotal> 
                            : null)
                        })
                    : 
                    currentArr.map(({id, isActive, type}) => {
                    return(
                        isActive ? 
                            <ChooseTotal key={id}>
                                <ButtonText> {type === 'middle' ? `Рант ` : `Ремень `} {titleParts[id-1].kind} <br /> {titleParts[id-1].model} <br /> <PriceSpan>{titleParts[id-1].price} руб.</PriceSpan> </ButtonText>
                            </ChooseTotal> 
                    : null)
                    }) 
                }
                </TotalContainer>
            
            <TotalP> 
                Итого: {total} руб.<br /> 
                {
                    differentBelts && !onlyRantsButton ? <span className='additional'>Доплата за разные ремешки: {bonus}</span> : null
                } 
            </TotalP>
            <DeliveryP>Доставка уже включена в конечную стоимость</DeliveryP>
            <YandexButton total={total} models={models} titleShop={titleShop}/>

        </TextBlockDiv>
    )
}

export default TextBlock;