import styled from 'styled-components';

export const HeaderContainer = styled.header`
    width: 100%;
    height: 50px;
    gap: 15px;

    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    h1{
        margin: 0;
    }

    img{
        width: 170px;
        height: auto;
        align-items: flex-start;
    }
    a{
        display: flex;
        align-items: flex-start;
    }
`;