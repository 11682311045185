import React from 'react';
import './App.css';
import SliderComponent from './components/slider/slider.component';
import Header from './components/header/header.component';
import Footer from './components/Footer/footer.component';
import { AppContainer } from './App.styles';

function App(){
    return(
        <AppContainer >
          <Header />
          <SliderComponent/>
          <Footer />
        </AppContainer >
    )
};

export default App;
