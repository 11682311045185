import React, {createContext, useState, useEffect } from 'react';
import { 
    prevSlide, 
    nextSlide,
} from './cart.utils';
import GA100_DATA from '../../DATA/PARTS/GA100';

export const CartContext = createContext({
    currentPARTS: {},
    currentArr: [],
    cartItems: [],
    url: [],
    cartItemsCount: 0,
    total: 0,
    topSlide: 0,
    middleSlide: 0,
    bottomSlide: 0,
    complete: true,
    originBelts: false,
    onlyRantsButton: false,
    onlyBeltsButton: false, 
    buttonLeft: () => {},
    buttonRight: () => {},
    addItem: () => {},
    removeItem: () => {},
    clearItemsFromCart: () => {},
});

const CartProvider = ({ children }) => {
    //Тип Деталей
    const [currentPARTS, setCurrentPARTS] = useState(GA100_DATA);
    const [currentArr, setCurrentArr] = useState(() => Object.keys(currentPARTS).map(key => currentPARTS[key]));

    const [topSlide, setTopSlide] = useState(1);
    const [middleSlide, setMiddleSlide] = useState(1);
    const [bottomSlide, setBottomSlide] = useState(1);
    const [originBelts, setOriginBelts] = useState(true); // разные или одинаковые ремешки
    const [complete, setComplete] = useState(true); // комплект или только детали
    const [onlyRantsButton, setOnlyRantsButton] = useState(false);
    const [onlyBeltsButton, setOnlyBeltsButton] = useState(false);
    const [url, setUrl] = useState([`${currentArr[0].items[topSlide-1].model}`, `${currentArr[1].items[middleSlide-1].model}`, `${currentArr[2].items[bottomSlide-1].model}`]);
 
    const buttonLeft = (type) => prevSlide(type, originBelts, topSlide, middleSlide, bottomSlide, setTopSlide, setMiddleSlide, setBottomSlide, currentPARTS);
    const buttonRight = (type) => nextSlide(type, originBelts, topSlide, middleSlide, bottomSlide, setTopSlide, setMiddleSlide, setBottomSlide, currentPARTS);

    useEffect(() => {
        setUrl([`${currentArr[0].items[topSlide-1].model}`, `${currentArr[1].items[middleSlide-1].model}`, `${currentArr[2].items[bottomSlide-1].model}`]);    
    }, [topSlide, middleSlide, bottomSlide, currentArr])

    useEffect(() => {  
        setTopSlide(1);
        setMiddleSlide(1);
        setBottomSlide(1);
        document.title = `Time x Team Custom - GA-100 Series`;
        setCurrentArr( Object.keys(currentPARTS).map(key => currentPARTS[key]) )   
    }, [currentPARTS])

    return <CartContext.Provider
        value={{
            currentPARTS,
            setCurrentPARTS,
            topSlide,
            setTopSlide,
            middleSlide,
            setMiddleSlide,
            bottomSlide,
            setBottomSlide,
            complete,
            originBelts,
            onlyRantsButton,
            onlyBeltsButton,
            setOnlyBeltsButton,
            setOnlyRantsButton,
            setOriginBelts,
            setComplete,
            buttonLeft,
            buttonRight,
            url,
            setUrl,
            currentArr
        }}
    >
        {children}
    </CartContext.Provider>
}

export default CartProvider;
