export const nextSlide = (type, originBelts, topSlide, middleSlide, bottomSlide, setTopSlide, setMiddleSlide, setBottomSlide,currentPARTS ) => {
  let itemArr = [];
  let itemsArray = Object.keys(currentPARTS).map(
    key =>  currentPARTS[key]
  );
  for( var i = 0; i < 3; i++){
    itemArr[i] = itemsArray[i].items.length
  }
  switch ( type ){
    case('top'):
      if(!originBelts){
        if( topSlide === itemArr[0] ){
          setTopSlide(1);
        }else{
          topSlide +=1;
          setTopSlide(topSlide)
        }
      }else{
        if( topSlide === itemArr[0] ){
          setTopSlide(1);
          setBottomSlide(1);
        }else{
          topSlide +=1;
          setTopSlide(topSlide);
          setBottomSlide(topSlide);
        }
      }
      break;
    case('middle'):
      if( middleSlide === itemArr[1] ){
        setMiddleSlide(1);
      }else{
        middleSlide +=1;
        setMiddleSlide(middleSlide);
      }
      break;
    case('bottom'):
      if(originBelts){
        if(bottomSlide === itemArr[2]){
          setBottomSlide(1);
          setTopSlide(1);
        }else{
          bottomSlide +=1;
          setBottomSlide(bottomSlide);
          setTopSlide(bottomSlide);
        }
      }else{
        if(bottomSlide === itemArr[2]){
          setBottomSlide(1);
        }else{
          bottomSlide +=1;
          setBottomSlide(bottomSlide);
        }
      }
      break;
    default:
      break;
  }
};

export const prevSlide = (type, originBelts, topSlide, middleSlide, bottomSlide, setTopSlide, setMiddleSlide, setBottomSlide, currentPARTS ) => {
  let itemArr = [];
  let itemsArray = Object.keys(currentPARTS).map(
    key =>  currentPARTS[key]
  );
  for( var i = 0; i < 3; i++){
    itemArr[i] = itemsArray[i].items.length
  }
  switch ( type ){
    case('top'):
      if(originBelts){
        if( topSlide === 1 ){
          setTopSlide(itemArr[0]);
          setBottomSlide(itemArr[0]);
        }else{
          topSlide -=1;
          setTopSlide(topSlide);
          setBottomSlide(topSlide);
        }
      }else{
        if( topSlide === 1 ){
          setTopSlide(itemArr[0]);
        }else{
          topSlide -=1;
          setTopSlide(topSlide);
        }
      }
      break;
    case('middle'):
      if( middleSlide === 1){
        setMiddleSlide(itemArr[1]);
      }else{
        middleSlide -=1;
        setMiddleSlide(middleSlide);
      }
      break;
    case('bottom'):
      if(originBelts){
        if( bottomSlide === 1){
          setBottomSlide(itemArr[2]);
          setTopSlide(itemArr[2]);
        }else{
          bottomSlide -=1;
          setBottomSlide(bottomSlide);
          setTopSlide(bottomSlide);
        }
      }else{
        if( bottomSlide === 1){
          setBottomSlide(itemArr[2]);
        }else{
          bottomSlide -=1;
          setBottomSlide(bottomSlide);
        }
      }
      break;
    default:
      break;
  }
}
