import React from 'react';
import { TitleContainer, BottomTitleContainer } from './title.style';

export const Title = () =>{
    return(
        <TitleContainer>
            <h1>G-SHOCK CUSTOM</h1>
            <h2>Качни свои G-SHOCK. <br /> Создавай так, как тебе угодно. <br /> Цветной. Простой. Любой. </h2>
        </TitleContainer>
    )
};

export const BottomTitle = () => {
    return(
        <BottomTitleContainer>
            <span>
                1. На фотографиях представлены детали(ремни, ранты). Cборка не является часами. Оформляя заказ, вы приобретаете только оригинальные детали.
            </span>
            <span>
                2. Фотографии деталей максимально приближенны к реальным. Оттенки деталей могут различаться из-за разного освещения. Мы работаем над этим. :)
            </span>
            <span>
                3. Детали подходят для моделей: GA-100, GA-110, GA-120, GA-140, GD-100, GD-110, GD-120, GAX-100. Ремни также подходят для GA-400, GAW-100, GA-700, GBA-400, GA-200.
            </span>
            <span>
                4. Также имеется возможность сразу приобрести кастомные новые часы. Собери их тут и обратитесь к нам удобным способом из Контактов ниже. ;)
            </span>
        </BottomTitleContainer>
    )
}