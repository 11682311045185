import React, { useState } from 'react';

import { DescriptionContainer, CloseButton, TextContainer } from './description.styles';

const Description = () => {

    const [isActive, setActive] = useState(false); // Чтобы работало, надо поставить true
    const [animate, setAnimate] = useState(true);

    function Animate(){
        if(isActive){
            setAnimate(!animate);
            setTimeout(() => {
                setActive(!isActive);
            }, 999);
        }
    }

    return(
        isActive ?
        <DescriptionContainer animate={animate}>
            <TextContainer animate={animate}>
                <CloseButton onClick={Animate}>х</CloseButton>
                <span>
                    Роднулькины, теперь можно полностью восстановить Ваши старенькие G-SHOCK или создать уникальную модель, 
                    которая будет только у Вас. А ещё Вы можете выбрать любую новую модель и сразу покачать ее на свой вкус, 
                    об этом мы позаботимся. Этот конструктор мы сделали специально для Вашего удобства! Пользуйтесь, создавайте, заказывайте.
                </span> 
                <p>Да Здравствует CUSTOM T🛠T</p>
            </TextContainer>
        </DescriptionContainer>
        : null
    )
};

export default Description;