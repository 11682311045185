import styled from 'styled-components';

export const FooterContainer = styled.footer`
    width: 100%;
    min-height: 22vh;

    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;

    background: #111;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    color: #fff;
    margin-top: 15px;
    padding: 1em 0;
    font-weight: 300;
    @media screen and ( max-width: 720px) {
        flex-direction: column;
    }
`;

export const Container = styled.div`
    width: 100%;
    gap: 1em;
    max-width: 1170px;
    margin: 0 auto;
    padding: 1em 0 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and ( max-width: 720px) {
        padding: 0;
        flex-direction: column;
    }
`;

export const Item = styled.div`
    width: 100%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and ( max-width: 720px) {
        max-width: unset;
        justify-content: flex-start;
        .footer-category{
            flex-direction: row !important;
            flex-wrap: wrap !important;
        }
    }
    .footer-mob-cont{
        .footer-link-container{
            display: flex;
            margin-top: 1em;
            flex-direction: row;
            gap: 1em;
            justify-content: center;
            a{
                width: 32px;
                height: 32px;
                display: flex;
                opacity: .33;
                font-size: 14px;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        h5{
            font-size: 20px;
            margin-top: 0;
            font-weight: 500;
            line-height: 1.1;
            margin-bottom: 1em;
        }
        .details{
            margin-top: 1em;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0;
            color: #888;
            li{
                /* margin: 5px 0; */
                padding: 0 0;
            }
            span{
                font-size: 16px;
                a{
                    color: #888;
                    margin: 0; 
                }
            }
        }
    }
    .footer-logo{
        max-width: 170px;
        margin: auto;
        border: 0;
    }
    .details-cell{
        
        gap: 3px;
        .p-b-head{
            color: #fff;
            float: left;
            font-size: 14px;
            width: 100%;
            margin-bottom: .5em;
        }
        .p-b-1{
            width: 60px;
            height: auto;
        }
    }
    .footer-category{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a{
            color: #888;
            font-size: 12px;
            transition: .2s ease;
            text-decoration: none;
            font-size: 11px;
            padding: 0 0 0 15px;
        }
        a:first-child{
            color: #fff;
            font-size: 14px;
        }
    }
`;

export const SpanAbout = styled.span`
    width: 100%;

    color: #888;
    font-size: 10px;

    padding: 0;
    margin: 0 0 30px 0;
`;