import styled from 'styled-components';

export const DescriptionContainer = styled.div`
    width: 100vw;
    height: 100vh;
    font-size: 12px;
    text-align: justify;
    text-indent: 1.5em;
    position: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 99;
    transition: 1s ease;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0,0,0,0.1);
    animation: 1s ${props => !props.animate ? 'whited' : null};
    @keyframes whited {
        from {
            background: rgb(0,0,0,0.1);
        }

        to {
            background: rgb(0,0,0,0.0);
        }
    }
`;

const ButtonParams = '24px';

export const TextContainer = styled.div`
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    width: 80%;
    margin: 0 5% 5%;
    max-width: 320px;
    padding: 5%;
    padding-top: 60px;
    height: 100%;
    max-height: 320px;
    position: relative;
    bottom: 0 !important;
    right: 0;
    left: 0;
    animation: 1s ${props => props.animate ? 'slidein' : 'slideout'};

    @keyframes slidein {
        from {
            transform: translateY(120%); 
        }

        to {
            transform: translateY(0); 
        }
    }

    @keyframes slideout {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(120%);
        }
    }

`;

export const CloseButton = styled.button`
    position: absolute;
    border: none;
    border-radius: 50%;
    background: #eee;
    color: #000;
    top: ${ButtonParams};
    right: ${ButtonParams};;
    width: ${ButtonParams};;
    height: ${ButtonParams};;
    transition: 0.2s ease;
    z-index: 2;
`;