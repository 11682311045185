import React from 'react';
import './button.styles.sass';

const YandexButton = ({ total, models, titleShop }) => {
    return(
        <form method="POST" action="https://money.yandex.ru/quickpay/confirm.xml" target="_blank">  
            <input type="hidden" name="receiver" value="410014158286223" />       
            <input type="hidden" name="label" value="$order_id" />
            <input type="hidden" name="quickpay-form" value="donate" />
            <input type="hidden" name="comment" value={models}/>   
            <input type="hidden" name="targets" value={titleShop} />
            <input type="hidden" name="label" value={`Заказ ${1}` } />   
            <input type="hidden" name="sum" value={ total } data-type="number" />     
            <input type="hidden" name="need-fio" value="true" />       
            <input type="hidden" name="need-phone" value="true" />    
            <input type="hidden" name="need-address" value="true" />
            <input type="hidden" name="need-email" value="false" />
            <input className="payButton" type="submit" value="Заказать"/>
        </form>
    )
};

export default YandexButton;