const GA100_DATA = {
    topBelts: {
        title: 'Ремешок (верх)',
        type: 'top',
        id: 1,
        isActive: true,
        isRant: false,
        imgPath: 't',
        items: [
            {
              id: 1,
              name: 'Прозрачный GA-400',
              kind: 'Глянцевый',
              model: 'GA-400SK-1A9',
              color: 'rgba(0,0,0,0.5)',
              price: 1230
            },
            {
              id: 2,
              name: 'Фиолетовый GA-140',
              kind: 'Матовый',
              model: 'GA-100DN-6A',
              color: '#000',
              price: 1020
            },
            {
              id: 3,
              name: 'Зеленый GA-700',
              kind: 'Матовый',
              model: 'GA-700UC-3',
              color: '#000',
              price: 840
            },
            {
              id: 4,
              name: 'Персиковый GA-110',
              kind: 'Матовый',
              model: 'GA-110DN-4A',
              color: '#000',
              price: 1020
            },
            {
              id: 5,
              name: 'Красный GA-700',
              kind: 'Матовый',
              model: 'GA-700-4A',
              color: '#000',
              price: 1060
            },
            {
              id: 6,
              name: 'Черный GA-100',
              kind: 'Матовый',
              model: 'GA-100-1A1',
              color: '#000',
              price: 880
            },
            {
              id: 7,
              name: 'Кожаный GA-100',
              kind: 'Уникальный',
              model: 'GA-100BT-1A',
              color: '#000',
              price: 1230
            },
            {
              id: 8,
              name: 'Серый GA-100',
              kind: 'Матовый',
              model: 'GA-100SD-8A',
              color: '#000',
              price: 1060
            },
            {
              id: 9,
              name: 'Зеленый GA-400',
              kind: 'Глянцевый',
              model: 'GA-400CS-9A',
              color: '#000',
              price: 1230
            },
            {
              id: 10,
              name: 'Черный GA-100',
              kind: 'Глянцевый',
              model: 'GA-100HC-1A',
              color: '#000',
              price: 1450
            },
            {
              id: 11,
              name: 'Белый GA-100',
              kind: 'Глянцевый',
              model: 'GA-100A-7A',
              color: '#000',
              price: 1230
            },
            {
              id: 12,
              name: 'Зеленый - GA-100',
              kind: 'Матовый',
              model: 'GA-100LN-3A',
              color: '#000',
              price: 1290
            },
            {
              id: 13,
              name: 'Бирюзовый GA-100',
              kind: 'Матовый',
              model: 'GA-100SN-3A',
              color: '#000',
              price: 1060
            },
            {
              id: 14,
              name: 'Черный GA-400',
              kind: 'Матовый',
              model: 'GA-400-1A',
              color: '#000',
              price: 700
            },
            {
              id: 15,
              name: 'Красный GA-400',
              kind: 'Матовый',
              model: 'GA-400-4B',
              color: '#000',
              price: 920
            },
            {
              id: 16,
              name: 'Белый GA-700',
              kind: 'Матовый',
              model: 'GA-700-7A',
              color: '#000',
              price: 1060
            },
            {
              id: 17,
              name: 'Песочный GA-700',
              kind: 'Матовый',
              model: 'GA-700UC-5A',
              color: '#000',
              price: 840
            },
            {
              id: 18,
              name: 'Черный GA-200',
              kind: 'Матовый',
              model: 'GA-200-1A',
              color: '#000',
              price: 1100
            },
            {
              id: 19,
              name: 'Красный GA-400',
              kind: 'Глянцевый',
              model: 'GA-400CX-4A',
              color: '#000',
              price: 1350
            },
            {
              id: 20,
              name: 'Оранжевый GA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-4B',
              color: '#000',
              price: 1100
            },
            {
              id: 21,
              name: 'Красный GA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-4A',
              color: '#000',
              price: 1100
            },
            {
              id: 22,
              name: 'Фиолетовый GD-100',
              kind: 'Глянцевый',
              model: 'GD-100SC-6',
              color: '#000',
              price: 1100
            },
            {
              id: 23,
              name: 'Оранжевый GA-110',
              kind: 'Матовый',
              model: 'GA-110MR-4A',
              color: '#000',
              price: 1150
            },
            {
              id: 24,
              name: 'Черный GA-700',
              kind: 'Матовый',
              model: 'GA-700-1E',
              color: '#000',
              price: 850
            },
            {
              id: 25,
              name: 'Серый GBA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-8B',
              color: '#000',
              price: 1100
            },
            {
              id: 26,
              name: 'Бирюзовый GA-400',
              kind: 'Матовый',
              model: 'GA-400A-2A',
              color: '#000',
              price: 1200
            },
            {
              id: 27,
              name: 'Синий GA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-2B',
              color: '#000',
              price: 1150
            },
            {
              id: 28,
              name: 'Зеленый GA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-3B',
              color: '#000',
              price: 1150
            },
            {
              id: 29,
              name: 'Розовый GA-400',
              kind: 'Глянцевый',
              model: 'GBA-400-4C',
              color: '#000',
              price: 1150
            },
            {
              id: 30,
              name: 'Серый GA-140',
              kind: 'Матовый',
              model: 'GA-140-2A',
              color: '#000',
              price: 1300
            },
            {
              id: 31,
              name: 'Синий GA-700',
              kind: 'Матовый',
              model: 'GA-700-2A',
              color: '#000',
              price: 850
            }
        ]
    },
    Rants: {
        title: 'Рант',
        id: 2,
        type: 'middle',
        isActive: true,
        isRant: true,
        imgPath: 'r',
        items: [
            {
              id: 1,
              name: 'Прозрачный GA-110',
              kind: 'Глянцевый',
              model: 'GA-110FRG-7A',
              color: '#000',
              price: 2000
            },
            {
              id: 2,
              name: 'Фиолетовый GA-110',
              kind: 'Матовый',
              model: 'GA-110DN-6A',
              color: '#000',
              price: 1200
            },
            {
              id: 3,
              name: 'Персиковый GA-110',
              kind: 'Матовый',
              model: 'GA-110DN-4A',
              color: '#000',
              price: 1200
            },
            {
              id: 4,
              name: 'Черный GA-100',
              kind: 'Матовый',
              model: 'GA-100-1A1',
              color: '#000',
              price: 1200
            },
            {
              id: 5,
              name: 'Кожаный GA-100',
              kind: 'Уникальный',
              model: 'GA-100BT-1A',
              color: '#000',
              price: 1290
            },
            {
              id: 6,
              name: 'Желтый GA-110',
              kind: 'Матовый',
              model: 'GA-110BC-9A',
              color: '#000',
              price: 1590
            },
            {
              id: 7,
              name: 'Синий GA-100',
              kind: 'Матовый',
              model: 'GA-100MC-2',
              color: '#000',
              price: 1290
            },
            {
              id: 8,
              name: 'Коричневый GA-110',
              kind: 'Матовый',
              model: 'GA-110GD-9B2',
              color: '#000',
              price: 1250
            },
            {
              id: 9,
              name: 'Зеленый GA-110',
              kind: 'Матовый',
              model: 'GA-110LP-3A',
              color: '#000',
              price: 1290
            },
            {
              id: 10,
              name: 'Зеленый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110NM-3A',
              color: '#000',
              price: 1590
            },
            {
              id: 11,
              name: 'Красный GA-110',
              kind: 'Глянцевый',
              model: 'GA-110NM-4A',
              color: '#000',
              price: 1590
            },
            {
              id: 12,
              name: 'Желтый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110NM-9A',
              color: '#000',
              price: 1590
            },
            {
              id: 13,
              name: 'Серый GA-140',
              kind: 'Матовый',
              model: 'GA-140-8A',
              color: '#000',
              price: 1290
            },
            {
              id: 14,
              name: 'Болотный GD-100',
              kind: 'Матовый',
              model: 'GD-100PS-3',
              color: '#000',
              price: 1290
            },
            {
              id: 15,
              name: 'Черный GA-100',
              kind: 'Матовый',
              model: 'GA-100-1A2',
              color: '#000',
              price: 1190
            },
            {
              id: 16,
              name: 'Черный GD-100',
              kind: 'Матовый',
              model: 'GD-100-1A',
              color: '#000',
              price: 1290
            },
            {
              id: 17,
              name: 'Черный GA-100',
              kind: 'Матовый',
              model: 'GA-100MC-1A4',
              color: '#000',
              price: 1090
            },
            {
              id: 18,
              name: 'Зеленый GD-100',
              kind: 'Матовый',
              model: 'GD-100MS-3E',
              color: '#000',
              price: 1190
            },
            {
              id: 19,
              name: 'Зеленый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110BR-5A',
              color: '#000',
              price: 1690
            },
            {
              id: 20,
              name: 'Серый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110BC-8A',
              color: '#000',
              price: 1590
            },
            {
              id: 21,
              name: 'Белый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110GBG-7A',
              color: '#000',
              price: 1490
            },
            {
              id: 22,
              name: 'Бирюзовый GA-110',
              kind: 'Матовый',
              model: 'GA-110SN-3E',
              color: '#000',
              price: 1290
            },
            {
              id: 23,
              name: 'Оранжевый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110SG-4A',
              color: '#000',
              price: 1290
            },
            {
              id: 24,
              name: 'Белый GD-100',
              kind: 'Матовый',
              model: 'GD-100WW-7',
              color: '#000',
              price: 1190
            },
            {
              id: 25,
              name: 'Белый GA-100',
              kind: 'Матовый',
              model: 'GA-100B-7A',
              color: '#000',
              price: 1490
            },
            {
              id: 26,
              name: 'Синий GA-110',
              kind: 'Глянцевый',
              model: 'GA-110MN-2A',
              color: '#000',
              price: 1690
            },
            {
              id: 27,
              name: 'Красный GA-100',
              kind: 'Матовый',
              model: 'GA-100B-4A',
              color: '#000',
              price: 1390
            },
            {
              id: 28,
              name: 'Оранжевый GA-110',
              kind: 'Матовый',
              model: 'GA-110MR-4A',
              color: '#000',
              price: 1190
            },
            {
              id: 29,
              name: 'Фиолетовый GD-100',
              kind: 'Глянцевый',
              model: 'GD-100SC-6',
              color: '#000',
              price: 1590
            },
            {
              id: 30,
              name: 'Желтый GA-110',
              kind: 'Глянцевый',
              model: 'GA-110GD-9B',
              color: '#000',
              price: 1390
            }
        ]
    },
    bottomBelts: {
        title: 'Ремешок (низ)',
        id: 3,
        isActive: true,
        isRant: false,
        type: 'bottom',
        imgPath: 'b',
        items: [
          {
            id: 1,
            name: 'Прозрачный GA-400',
            kind: 'Глянцевый',
            model: 'GA-400SK-1A9',
            color: 'rgba(0,0,0,0.5)',
            price: 1230
          },
          {
            id: 2,
            name: 'Фиолетовый GA-140',
            kind: 'Матовый',
            model: 'GA-100DN-6A',
            color: '#000',
            price: 1020
          },
          {
            id: 3,
            name: 'Зеленый GA-700',
            kind: 'Матовый',
            model: 'GA-700UC-3',
            color: '#000',
            price: 840
          },
          {
            id: 4,
            name: 'Персиковый GA-110',
            kind: 'Матовый',
            model: 'GA-110DN-4A',
            color: '#000',
            price: 1020
          },
          {
            id: 5,
            name: 'Красный GA-700',
            kind: 'Матовый',
            model: 'GA-700-4A',
            color: '#000',
            price: 1060
          },
          {
            id: 6,
            name: 'Черный GA-100',
            kind: 'Матовый',
            model: 'GA-100-1A1',
            color: '#000',
            price: 880
          },
          {
            id: 7,
            name: 'Кожаный GA-100',
            kind: 'Уникальный',
            model: 'GA-100BT-1A',
            color: '#000',
            price: 1230
          },
          {
            id: 8,
            name: 'Серый GA-100',
            kind: 'Матовый',
            model: 'GA-100SD-8A',
            color: '#000',
            price: 1060
          },
          {
            id: 9,
            name: 'Зеленый GA-400',
            kind: 'Глянцевый',
            model: 'GA-400CS-9A',
            color: '#000',
            price: 1230
          },
          {
            id: 10,
            name: 'Черный GA-100',
            kind: 'Глянцевый',
            model: 'GA-100HC-1A',
            color: '#000',
            price: 1450
          },
          {
            id: 11,
            name: 'Белый GA-100',
            kind: 'Глянцевый',
            model: 'GA-100A-7A',
            color: '#000',
            price: 1230
          },
          {
            id: 12,
            name: 'Зеленый - GA-100',
            kind: 'Матовый',
            model: 'GA-100LN-3A',
            color: '#000',
            price: 1290
          },
          {
            id: 13,
            name: 'Бирюзовый GA-100',
            kind: 'Матовый',
            model: 'GA-100SN-3A',
            color: '#000',
            price: 1060
          },
          {
            id: 14,
            name: 'Черный GA-400',
            kind: 'Матовый',
            model: 'GA-400-1A',
            color: '#000',
            price: 700
          },
          {
            id: 15,
            name: 'Красный GA-400',
            kind: 'Матовый',
            model: 'GA-400-4B',
            color: '#000',
            price: 920
          },
          {
            id: 16,
            name: 'Белый GA-700',
            kind: 'Матовый',
            model: 'GA-700-7A',
            color: '#000',
            price: 1060
          },
          {
            id: 17,
            name: 'Песочный GA-700',
            kind: 'Матовый',
            model: 'GA-700UC-5A',
            color: '#000',
            price: 840
          },
          {
            id: 18,
            name: 'Черный GA-200',
            kind: 'Матовый',
            model: 'GA-200-1A',
            color: '#000',
            price: 1100
          },
          {
            id: 19,
            name: 'Красный GA-400',
            kind: 'Глянцевый',
            model: 'GA-400CX-4A',
            color: '#000',
            price: 1350
          },
          {
            id: 20,
            name: 'Оранжевый GA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-4B',
            color: '#000',
            price: 1100
          },
          {
            id: 21,
            name: 'Красный GA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-4A',
            color: '#000',
            price: 1100
          },
          {
            id: 22,
            name: 'Фиолетовый GD-100',
            kind: 'Глянцевый',
            model: 'GD-100SC-6',
            color: '#000',
            price: 1100
          },
          {
            id: 23,
            name: 'Оранжевый GA-110',
            kind: 'Матовый',
            model: 'GA-110MR-4A',
            color: '#000',
            price: 1150
          },
          {
            id: 24,
            name: 'Черный GA-700',
            kind: 'Матовый',
            model: 'GA-700-1E',
            color: '#000',
            price: 850
          },
          {
            id: 25,
            name: 'Серый GBA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-8B',
            color: '#000',
            price: 1100
          },
          {
            id: 26,
            name: 'Бирюзовый GA-400',
            kind: 'Матовый',
            model: 'GA-400A-2A',
            color: '#000',
            price: 1200
          },
          {
            id: 27,
            name: 'Синий GA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-2B',
            color: '#000',
            price: 1150
          },
          {
            id: 28,
            name: 'Зеленый GA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-3B',
            color: '#000',
            price: 1150
          },
          {
            id: 29,
            name: 'Розовый GA-400',
            kind: 'Глянцевый',
            model: 'GBA-400-4C',
            color: '#000',
            price: 1150
          },
          {
            id: 30,
            name: 'Серый GA-140',
            kind: 'Матовый',
            model: 'GA-140-2A',
            color: '#000',
            price: 1300
          },
          {
            id: 31,
            name: 'Синий GA-700',
            kind: 'Матовый',
            model: 'GA-700-2A',
            color: '#000',
            price: 850
          }
      ]
    }
};

export default GA100_DATA;