import styled from 'styled-components';

export const SliderDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;

    width: 100%;
    min-height: 64vh;

    @media screen and ( max-width: 720px ) {
        flex-direction: column-reverse;
        padding: 1em 0;
    }
`;

export const SliderContainer = styled.div`
    width: 100%;
    max-width: 1170px;

    margin: 1em auto;
    padding: 15px 0;

    border-radius: 30px;
    background-color: #fff;
    box-shadow: 5px 5px 10px rgba(0,0,0,.05);

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
`;