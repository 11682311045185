import styled from 'styled-components';

export const PartCollectionDiv = styled.div`
    display: flex;
    flex-direction: ${props => props.dirFlex ? 'row' : 'column'};
    align-items: flex-end;
    margin: 1em 0;
    div{
        width: 200px;
        &:last-child{
            transform: ${props => props.dirFlex ? 'rotate(180deg)' : null};
        }
    }
`;