import React, { useContext } from 'react';
import { PartImg, PartDiv, DialContainer,ButtonArrow } from './part.styles';
import { ArrowLeft, ArrowRight } from'../arrows/arrows.component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


import { CartContext } from '../../providers/cart/cart.provider';

const Part = ({ id, imgPath, type, isActive, isRant, buttonMobile }) => {

    const { onlyBeltsButton, buttonLeft, buttonRight, url } = useContext(CartContext);

    let right = true;
    
    if( onlyBeltsButton ){
        right = false;
    }

    return(
        isActive ? 
            <PartDiv>
                    {
                        buttonMobile && right ?
                        <ButtonArrow left onClick={() => buttonLeft(type)}><ArrowLeft /></ButtonArrow>
                        : null
                    }       
                    <PartImg 
                        boolFlex={onlyBeltsButton} 
                        src={`https://www.time-team.ru/image/${imgPath}/${url[id-1]}.${isRant ? `png` : 'jpg'}`}
                        effect="opacity"
                        />
                    {
                        isRant ? <DialContainer src={`https://www.time-team.ru/image/r/cover.png`} /> : null
                    }
                    {
                        buttonMobile && right ?
                        <ButtonArrow right onClick={() => buttonRight(type)}><ArrowRight /></ButtonArrow> 
                        : null
                    }
            </PartDiv>
        :
        null
    )
}

export default Part;