import React, { useContext, useState, useEffect, useCallback } from 'react';
import { CartContext } from '../../providers/cart/cart.provider';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { CollectionContainer, CollectionItem, Tabs, Content, TabPane } from './quicktype.styles';

const ImgMap = styled(LazyLoadImage)`
    width: 100%;
`;


const QuickType = () => {
    const { 
        setMiddleSlide, 
        middleSlide, 
        setTopSlide, 
        topSlide, 
        bottomSlide, 
        setBottomSlide, 
        currentArr,
        onlyBeltsButton, 
        originBelts,
        onlyRantsButton } = useContext(CartContext);

    const [tabs, setTabs] = useState([true, false, false])

    let rant = currentArr[1].items;
    let belt = currentArr[0].items;

    function setBelt(id){
        if( originBelts ){
            setTopSlide(id)
            setBottomSlide(id)
        }else{
            setTopSlide(id)
        }
    } 

    const getTabs = useCallback(() => {
        if (originBelts && tabs[2]){
            return setTabs(() => [false, true, false])
        }else if(onlyBeltsButton && tabs[0]){
            return setTabs(() => [false, true, false])
        }else if(onlyRantsButton && !tabs[0]){
            return setTabs(() => [true, false, false])
        }
    },[tabs, originBelts, onlyBeltsButton, onlyRantsButton])

    useEffect(() =>{
        getTabs();
    }, [getTabs])  

    return(
        <>
        <Tabs>
            {!onlyBeltsButton ? 
                <TabPane active={tabs[0] ? true : false} onClick={() => {if(!tabs[0]) setTabs([true, false, false])}}>Рант</TabPane> : null}
            {!onlyRantsButton && originBelts ? 
                <TabPane active={tabs[1] ? true : false} onClick={() => {if(!tabs[1]) setTabs([false, true, false])}}>Ремешки</TabPane> : null}
            {!originBelts && !onlyRantsButton ? <>
                <TabPane active={tabs[1] ? true : false} onClick={() => {if(!tabs[1]) setTabs([false, true, false])}}>Верх</TabPane>
                <TabPane active={tabs[2] ? true : false} onClick={() => {if(!tabs[2]) setTabs([false, false, true])}}>Низ</TabPane>
            </> : null}
        </Tabs>
        <Content>
            {/* Ранты */}
            {tabs[0] === true && !onlyBeltsButton ? 
                <CollectionContainer rant>
                {
                    rant.map(({id, name, model}) => {
                        return(
                            <CollectionItem className={middleSlide === id ? `active` : null} onClick={() => {
                                setMiddleSlide(id);
                            }} key={id}>
                                <ImgMap 
                                    alt={name} 
                                    effect="opacity"
                                    src={`https://www.time-team.ru/image/r/${model}.png`} />
                            </CollectionItem>
                        )
                    })
                } 
            </CollectionContainer>
            : null}
            {/* Ремешки и Ремешок верх*/}
            { tabs[1] === true && !onlyRantsButton ?
            <CollectionContainer belt={originBelts ? false : true}>
                {
                    belt.map(({id, name, model}) => {
                        return(
                            <CollectionItem belts={originBelts ? true : false} className={topSlide === id ? `active` : null} onClick={() => {
                                setBelt(id);
                            }} key={id}>
                                <ImgMap
                                    alt={name}
                                    effect="opacity"
                                    src={`https://www.time-team.ru/image/t/${model}.jpg`} />
                                {originBelts ? 
                                <ImgMap
                                    alt={name}
                                    effect="opacity" 
                                    src={`https://www.time-team.ru/image/b/${model}.jpg`} />
                                : null}
                            </CollectionItem>
                        )
                    })
                } 
            </CollectionContainer>
            : null}
            {/* Ремешок - Низ */}
            {tabs[2] === true && !onlyRantsButton ? 
            <CollectionContainer belt>
                {
                    belt.map(({id, name, model}) => {
                        return(
                            <CollectionItem rotate={onlyBeltsButton} className={bottomSlide === id ? `active` : null} onClick={() => {
                                setBottomSlide(id);
                            }} key={id}>
                                <ImgMap 
                                    alt={name}
                                    effect="opacity"
                                    src={`https://www.time-team.ru/image/b/${model}.jpg`} />
                            </CollectionItem>
                        )
                    })
                }
            
            </CollectionContainer>
            : null}
        </Content>
        
        </>
    )
}

export default QuickType;