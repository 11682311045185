import React from 'react';
import { FooterContainer,Container,Item,SpanAbout } from './footer.styles';

const Footer = () => {
    return(
        <FooterContainer>
            <SpanAbout>У нас вы можете приобрести часы фирмы CASIO — одного из ведущих производителей часовой промышленности с более чем шестидесятилетней историей. Мы являемся официальным дилером компании CASIO и осуществляем доставку часов по всему Миру.</SpanAbout>
            <Container>
                <Item>
                    <img className="footer-logo" src="https://time-team.ru/image/regular/logo-white.png" alt="timeteamshop" />
                </Item>
                <Item>
                <div className="footer-category">
                    <a href="https://time-team.ru/katalog">МОДЕЛИ</a>
                    <a href="https://time-team.ru/g-shock">G-SHOCK</a>
                    <a href="https://time-team.ru/BABY-G">BABY-G</a>
                    <a href="https://time-team.ru/PRO-TREK">PRO-TREK</a>
                    <a href="https://time-team.ru/SHEEN">SHEEN</a>
                    <a href="https://time-team.ru/EDIFICE">EDIFICE</a>
                    <a href="https://time-team.ru/COLLECTION">COLLECTION</a>
                    <a href="https://time-team.ru/SPORTS">SPORTS</a>
                    <a href="https://time-team.ru/VINTAGE">VINTAGE</a>  
                </div>
                </Item>
                <Item>
                    <div className="details-cell">
                        <div className="p-b-head">Способы оплаты:</div>
                        <img className="p-b-1" alt={'payment and delivery'} src="https://time-team.ru/image/regular/mastercard.png" />
                        <img className="p-b-1" alt={'payment and delivery'} src="https://time-team.ru/image/regular/maestro.png"/>
                        <img className="p-b-1" alt={'payment and delivery'} src="https://time-team.ru/image/regular/visa.png"/>
                        <p>Безопасная оплата</p>
                    </div>
                    <div className="details-cell">
                        <div className="p-b-head">Способы доставки:</div>
                        <img className="p-b-1" alt={'payment and delivery'} src="https://time-team.ru/image/regular/pochta-rossii.svg"/>
                        <img className="p-b-1" alt={'payment and delivery'} src="https://time-team.ru/image/regular/cdek.svg"/>
                    </div>
                </Item>
                <Item>
                    <div className="footer-mob-cont">
                        <h5>Наши контакты:</h5>
                        <ul className="details">
                            <li><i className="fa fa-map-marker"></i><span><span className="heavy-font"></span>г. Красноярск, пр. Мира, 96 <br/> Ежедневно с 11:00 до 20:00</span></li>
                            <li><i className="fa fa-envelope"></i><span><span className="heavy-font"></span><a href="mailto:timeteamshop@gmail.com">timeteamshop@gmail.com</a></span></li>
                            <li><i className="fa fa-phone"></i><span><span className="heavy-font"></span><a href="tel:+78002001427">8 (800) 200-14-27</a></span></li>
                            <li><span><a href={`https://time-team.ru/contact`}>Контакты и Юр. Информация</a></span></li>
                        </ul>
                        <div className="footer-link-container">
                            <a className="img-logo-footer" href="https://www.instagram.com/timeteamshop/">
                                <img src="https://time-team.ru/image/footer/instagram-logo.png" target="_blank" alt="Time x Team Instagram" />
                            </a>
                            <a className="img-logo-footer" href="https://vk.com/timeteam">
                                <img src="https://time-team.ru/image/footer/vk-logo.png" target="_blank" alt="Time x Team VK" />
                            </a>
                        </div>
                    </div>
                </Item>
            </Container>
        </FooterContainer>
    )
}

export default Footer;