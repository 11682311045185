import styled from 'styled-components';

export const TextBlockDiv = styled.div`
    padding: 15px;
    display: flex;
    max-width: 300px;
    flex-direction: column; 
    gap: 10px;
    color: rgb(29, 29, 31);
    @media screen and ( max-width: 720px) {
        width: 100%;
        padding: 0;
    }
`;

const buttonParam = `42px`

export const ButtonText = styled.span`
    line-height: 1.2;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
`;

export const PriceSpan = styled.span`
    font-size: 10px;
    font-weight: 400;
`;

export const ButtonArrow = styled.button`
    width: ${buttonParam};
    height: ${buttonParam};
    border-radius: 50%;
    background-color: rgba(30,30,30,1.0);
    color: #fff;
    padding: 0;
    border: none;
    font-weight: lighter;
    transition: 0.2s ease;
    &:hover{
        transform: scale(1.05);
    }
    &:active{
        transform: scale(0.97);
    }
`;

export const TotalContainer = styled.div`
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    padding: 10px 0;
    border-radius: 2.6rem;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.05);
    h3{
        display: block;
        width: 100%;
        font-size: 1.3rem;
        margin: 0 0 0.78rem 0;
        padding:0;
    }
`;



export const ChooseTotal = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TotalP = styled.p`

    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;

    .additional{
        font-size: 10px;
        margin: 0;
    }
`;

export const DeliveryP = styled.p`
    font-weight: lighter;
    font-size: 11px;
    margin: 0;
`;

export const SwitchContainer = styled.div`
    gap: 15px;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: center;
    align-items: space-between;
    width: 100%;
`;

export const SwitchTypes = styled.button`
    border-radius: 24px;
    padding: 0.6em 0.8em;
    border: none;

    background-color: #eee;
    
    font-weight: bold;
    box-shadow: 0 0 5px rgba(0,0,0,0.06);
    transition: 0.5s ease;
    &:hover{
        background-color: #ccc;
    }
`;
