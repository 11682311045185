import React from 'react';
import { HeaderContainer } from './header.styles';

const Header = () => {
    return(
        <HeaderContainer>
            <a href="https://time-team.ru" alt="Time x Team">
                <img alt={`Time x Team`} src={`https://time-team.ru/image/catalog/logo.png`} />
            </a>
        </HeaderContainer>
    )
};

export default Header;