import React, {useContext} from 'react';
import { CartContext } from '../../providers/cart/cart.provider';

import { RandButton } from './random.styles';

const Random = () => {
    const {setBottomSlide, setMiddleSlide, setTopSlide, originBelts, currentPARTS} = useContext(CartContext);

    let itemArr = [];
    let itemsArray = Object.keys(currentPARTS).map(
        key =>  currentPARTS[key]
    );
    for( var i = 0; i < 3; i++){
        itemArr[i] = itemsArray[i].items.length
    }

    function randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }

    let randomParts = () => {
        if(originBelts){
            let belts = randomInteger(1, 30);
            setTopSlide(belts);
            setBottomSlide(belts);
        }else{
            setTopSlide(randomInteger(1,itemArr[0]));
            setBottomSlide(randomInteger(1,itemArr[2]));
        }
        setMiddleSlide(randomInteger(1,itemArr[1]));
        
    }


    return(
        <RandButton
            className={`random-click`}
            onClick={() => {
                randomParts()
            }}
        >
            Случайный
        </RandButton>
    )
}

export default Random;