import React from 'react';
import PartCollection from '../parts-collection/part-collection.component';
import TextBlock from '../text-block/text-block.component';
import Description from '../../components/description/description.component';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs.component';
import { Title, BottomTitle } from '../../components/title/title.component';

import { SliderDiv, SliderContainer } from './slider.styles';

const SliderComponent = () => {
    return(
        <SliderContainer> {/* SliderContainer - горизонтальный наполнитель */}
            <Breadcrumbs />
            <Title/>
            <SliderDiv> {/* SliderDiv - линейный наполнитель */}
                <Description />
                <PartCollection />
                <TextBlock />
            </SliderDiv>
            <BottomTitle/>
        </SliderContainer>
    )
}

export default SliderComponent;