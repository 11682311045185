import styled from 'styled-components';

export const TitleContainer = styled.div`
    width: 100%;
    color: #222;
    margin-bottom: 25px;

    h1{
        font-weight: 800;
        font-size: 24px;
        margin-top: 0;
        text-transform: uppercase;
        line-height: 1.6;
        color: inherit;
    }

    h2{
        font-size:16px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.4;
        color: inherit;
    }

    span{
        font-size: 14px;
    }

`;

export const BottomTitleContainer = styled.div`
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    span{
        margin: 0 15px;
        font-size: 9px;
        color: #888;
        line-height: 1;
        margin-bottom: 5px;
        float: left;
        text-align: left; 
    }
`;